import { useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import { NormalizedBuildFlowCollection, NormalizedCollection, NormalizedVariant } from '@ts/index';
import { calculateDiscount } from '@utils/discount';
import { addAndDeleteVariantsFromDict, LOCALE_DICT } from '..';

type TopsDict = { [key: string]: NormalizedVariant };

export default function useBuildYourBundle(collection: NormalizedCollection | NormalizedBuildFlowCollection) {
	const [size, setSize] = useState<number>(4);
	const { locale } = useRouter();

	const updatePriceTop = (handle, sizeBundle: number) => {
		const topCollection = collection.products.find(product => product.handle === handle) as NormalizedVariant;
		return {
			...topCollection,
			price: {
				...topCollection.price,
				amount: calculateDiscount(sizeBundle === 3 ? 0.1 : 0.15, topCollection.price.amount),
			},
			compareAtPrice: {
				...topCollection.price,
			},
		};
	};

	const reducer = (
		state: TopsDict,
		action: {
			type: string;
			top?: NormalizedVariant;
			size?: number;
		}
	) => {
		switch (action.type) {
			case 'add':
			case 'delete': {
				const newTops = addAndDeleteVariantsFromDict(action.top, state);
				if (Object.keys(newTops).length > action.size) {
					return state;
				}
				return newTops;
			}
			case 'newSize': {
				setSize(action.size);
				const keys = Object.keys(state);
				if (action.size < keys.length) {
					const newTops = Object.values(state).slice(0, 3);
					const newTopsDict = newTops.reduce(
						(acc, top) => ({ ...acc, [top.handle]: updatePriceTop(top.handle, action.size) }),
						{}
					);
					return newTopsDict;
				}
				return Object.values(state).reduce(
					(acc, top) => ({ ...acc, [top.handle]: updatePriceTop(top.handle, action.size) }),
					{}
				);
			}
			case 'reset': {
				return {};
			}
			case 'update': {
				return Object.values(state).reduce((acc, top) => {
					const newTop = collection.products.find(product => product.handle === top.handle);
					if (newTop) {
						return { ...acc, [newTop.handle]: newTop };
					}
				}, {});
			}
			default:
				break;
		}
	};

	const [tops, dispatch] = useReducer(reducer, {} as TopsDict);

	const handleTop = (top: NormalizedVariant) => {
		dispatch({ type: 'add', top, size });
	};

	useEffect(() => {
		const keys = Object.keys(tops);
		if (keys.length && collection?.products && LOCALE_DICT[locale].currencyCode !== tops[keys[0]]?.price.currencyCode) {
			dispatch({ type: 'update' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection]);

	const priceTops = Object.values(tops).reduce((acc: number, top: NormalizedVariant) => acc + top.price.amount, 0) as number;

	const topsLength = Object.keys(tops).length;
	const topsNeeded = size - topsLength;
	const isFullBundle = topsNeeded === 0;

	return {
		tops,
		handleTop,
		size,
		setSize,
		priceTops,
		topsLength,
		isFullBundle,
		topsDispatch: dispatch,
		updatePriceTop,
	};
}
